import React, {useMemo} from 'react';
import {Platform, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {variance} from '../../styling';
import {useStrings} from '../../Root/hooks';
import WebCurrency from '../shared/components/WebCurrency';
import {
  CalculationPriceText,
  TopPrice,
  TopPriceText,
  TotalPriceItemContainer,
} from '../shared/shared';
import TotalPriceRegular from '../PoolMinersTab/components/TotalPrice/TotalPriceRegular';
import TotalPriceDiscounts from '../PoolMinersTab/components/TotalPrice/TotalPriceDiscounts';
import {OfferDiscount} from '../../InAppOffersService';
import useIntlFormatter from '../../hooks/useIntlFormatter';

interface TotalPriceViewProps {
  count?: string | number;
  pricePerCount?: string;
  currency?: string;
  totalPrice?: string;
  period: string | undefined;
  oldPrice?: string;
  save?: number | string;
  discounts?: OfferDiscount[];
}

export default observer((props: TotalPriceViewProps) => {
  const {
    count,
    pricePerCount,
    discounts,
    save,
    oldPrice,
    period,
    totalPrice,
    currency,
  } = props;
  const {intFormatter} = useIntlFormatter();

  const titleCalculation = useMemo(() => {
    if (!totalPrice) {
      return null;
    }
    return `${count} x ${
      pricePerCount && intFormatter(pricePerCount)
    } ${currency} = ${intFormatter(totalPrice)} ${currency}`;
  }, [count, pricePerCount, currency, totalPrice, intFormatter]);
  const string = useStrings();
  if (!titleCalculation) {
    return null;
  }

  return (
    <RootView>
      <TotalPriceRegular
        oldPrice={oldPrice}
        save={save}
        currency={currency || ''}
      />
      <TotalPriceDiscounts discounts={discounts || []} />
      <TotalPriceItemContainer noBorder>
        <TopPrice>
          <TopPriceText sm>
            {string['purchase.poolMiners.totalPrice']}
          </TopPriceText>
          <TopPriceText>
            {Platform.OS === 'web' ? '~' : ''}
            {totalPrice && intFormatter(totalPrice)} {currency}{' '}
            {period ? `/ ${period}` : ''}
          </TopPriceText>
        </TopPrice>
        <CalculationPriceText>{titleCalculation}</CalculationPriceText>
        <WebCurrency />
      </TotalPriceItemContainer>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        paddingHorizontal: 15,
      },
    }),
  },
}));
