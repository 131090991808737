import {Platform} from 'react-native';

/**
 * @deprecated get rid of it
 * use DetectPlatform instead
 */
export const useDetectPlatform = () => {
  const isWeb = Platform.OS === 'web';
  const isAndroid = Platform.OS === 'android';
  let isIOS = Platform.OS === 'ios';
  let isWindows = false;
  let isMacOS = false;
  let isIOSWeb = false;

  if (isWeb) {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('win')) {
      isWindows = true;
    }

    if (userAgent.includes('mac')) {
      isMacOS = true;
    }

    const isIOSDevice =
      /ipad|iphone|ipod/.test(userAgent) &&
      typeof window.MSStream === 'undefined';

    if (isIOSDevice) {
      isIOS = true;
      isIOSWeb = true; // Веб iOS
    }
  }

  return {isWeb, isIOS, isMacOS, isAndroid, isWindows, isIOSWeb};
};
