import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet} from 'react-native';
import ActionModal from '../modals/ActionModal';
import CalendarModalView, {CalendarModalViewProps} from './CalendarModalView';

export type CalendarModalProps = CalendarModalViewProps & {
  isVisible: boolean;
};

export default observer<CalendarModalProps>(function CalendarModal(props) {
  const {isVisible, onDateSelect, close, initialDate} = props;
  if (!isVisible) {
    return null;
  }
  return (
    <ActionModal contentStyle={styles.root} onDismiss={close}>
      <CalendarModalView close={close} onDateSelect={onDateSelect} initialDate={initialDate} />
    </ActionModal>
  );
});
const styles = StyleSheet.create({
  root: {
    maxWidth: 372,
    borderWidth: 0,
  },
});
