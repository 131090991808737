import {ReadonlyDeep} from 'type-fest';
import React from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigation} from '@react-navigation/native';
import {useRoot, useStrings} from '../../Root/hooks';
import {ActionItem, ActionKey, SetupButton} from './SetupButton';
import {expr} from 'mobx-utils';
import {
  MoveWorkerToGroupModal,
  RemoveWorkerModal,
  RenameWorkerModal,
} from '../modals';
import {useBoolean} from '../../hooks';
import {
  WorkerEntity,
  WorkerType,
} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import getWorkerName from '../getters/getWorkerName';
import {DashboardScreenProps} from '../DashboardScreenProps';
import isWorkerDisabled from '../checkers/isWorkerDisabled';

export interface SlotSetupButtonProps {
  worker: ReadonlyDeep<WorkerEntity>;
  onDeleted?: () => void;
  hideKeyList?: ActionKey[];
}

export default observer(
  ({worker, onDeleted, hideKeyList = []}: SlotSetupButtonProps) => {
    const [moveModalShown, showMoveModal, hideMoveModal] = useBoolean();
    const [renameModalShown, showRenameModal, hideRenameModal] = useBoolean();
    const [removeModalShown, showRemoveModal, hideRemoveModal] = useBoolean();

    const navigation = useNavigation<DashboardScreenProps['navigation']>();

    const {
      currentSubscriptionState: {isPlatformSupported},
      navigationContainer,
      dashboardStore,
    } = useRoot();
    const {dashboardRequest} = dashboardStore;
    const strings = useStrings();
    const fullyDisabled = isWorkerDisabled(worker) || !isPlatformSupported;
    const _hideWorkerList = [...hideKeyList];
    if (worker.expired === false && worker.worker_type === WorkerType.Pool) {
      _hideWorkerList.push(ActionKey.Remove);
    }

    const isMoveButtonVisible = expr(() =>
      dashboardRequest.value?.groups
        ? dashboardRequest.value.groups.length > 1
        : false,
    );
    const actions: ActionItem[] = expr(() => [
      {
        key: ActionKey.Details,
        title: strings['worker.details'],
        onPress: () => {
          navigation.navigate('WorkerDetails', {
            workerId: worker.id,
          });
        },
        disabled: fullyDisabled,
      },
      {
        key: ActionKey.Schedule,
        title: strings['worker.schedule'],
        onPress: () => {
          navigation.navigate('Schedule', {
            ownerName: getWorkerName(worker),
            workerIds: [worker.id],
            goBackState: navigationContainer.ref?.getRootState(),
          });
        },
        disabled: fullyDisabled,
      },
      ...(isMoveButtonVisible
        ? [
            {
              key: ActionKey.Move,
              title: strings['worker.move'],
              onPress: showMoveModal,
              disabled: !isPlatformSupported,
            },
          ]
        : []),
      {
        key: ActionKey.Rename,
        title: strings['action.rename'],
        onPress: showRenameModal,
        disabled: !isPlatformSupported,
      },
      {
        key: ActionKey.Remove,
        title: strings['action.remove'],
        onPress: showRemoveModal,
        disabled: !isPlatformSupported,
      },
    ]).filter((a) => !_hideWorkerList?.includes(a.key));
    return (
      <>
        <SetupButton actions={actions} />
        {moveModalShown && (
          <MoveWorkerToGroupModal
            onDismiss={hideMoveModal}
            workerId={worker.id}
          />
        )}
        {renameModalShown && (
          <RenameWorkerModal onDismiss={hideRenameModal} worker={worker} />
        )}
        {removeModalShown && (
          <RemoveWorkerModal
            onDeleted={onDeleted}
            onDismiss={hideRemoveModal}
            worker={worker}
          />
        )}
      </>
    );
  },
);
