import {range} from 'lodash';
import {useMemo} from 'react';
import {useDateFormatting} from '../hooks/useDateFormate';

export const useCalendarInfo = () => {
  const localeNames = useDateFormatting();
  const days = range(0, 7);
  const months = range(0, 12);
  return useMemo(
    () => ({
      days: days.map((day) => localeNames.formatLocaleDays(day)),
      month: months.map((month) => localeNames.formatLocaleMonth(month)),
      shortDays: days.map((day) => localeNames.formatLocaleShortDays(day)),
    }),
    [localeNames, days, months],
  );
};

const getDayInMonth = (month: number, year: number) =>
  new Date(year, month + 1, 0).getDate();

export const getMonthInfo = (month: number, year: number, date: Date) => ({
  daysInMonth: getDayInMonth(month, year),
  firstDate: new Date(year, month, 1).getDay(),
  prevDaysInMonth: getDayInMonth(month - 1, year),
  activeDay: date.getDate(),
  activeMonth: month,
});

export const getCellCalendarInfo = (
  monthInfo: MonthInfo,
  cellCount: number,
) => {
  let calendarData = new Array(cellCount).fill(0);

  for (let i = 0; i < monthInfo.firstDate; i++) {
    calendarData[i] = monthInfo.prevDaysInMonth - monthInfo.firstDate + i + 1;
  }

  for (
    let i = monthInfo.firstDate;
    i < monthInfo.daysInMonth + monthInfo.firstDate;
    i++
  ) {
    calendarData[i] = i - monthInfo.firstDate + 1;
  }

  for (
    let i = monthInfo.daysInMonth + monthInfo.firstDate;
    i < calendarData.length;
    i++
  ) {
    calendarData[i] = i - (monthInfo.daysInMonth + monthInfo.firstDate) + 1;
  }

  return calendarData;
};

export type MonthInfo = {
  daysInMonth: number;
  firstDate: number;
  prevDaysInMonth: number;
  activeDay: number;
  activeMonth: number;
};
