import {MinerOffer, PaymentMethod} from '../../InAppOffersService';
import {IntlFormatter} from '../../IntlFormatter';
import {ProductOffer} from './sections/TotalPriceSection';

export function processProductOffer(
  offer: MinerOffer,
  method: PaymentMethod,
  lang: string,
): ProductOffer | undefined {
  const payment = offer.payments.get(method);
  if (!payment) {
    return undefined;
  }
  const {
    uiPrice,
    currency,
    pricePerMonth,
    discountsDifferencePrice,
    uiDiscountsDifferencePrice,
    discounts,
    uiOldPrice,
  } = payment;
  const price =
    method === PaymentMethod.InApp
      ? uiPrice
      : `~${IntlFormatter.formatNumber(uiPrice, lang)}`;
  return {
    uiPrice: price,
    questionMarkVisible: method !== PaymentMethod.InApp,
    helperPrice: undefined,
    pricePerMonth,
    name: offer.poolMinerConfig.name,
    currency,
    uiOldPrice,
    interval: offer.interval,
    discountsDifferencePrice,
    uiDiscountsDifferencePrice,
    discounts: discounts ?? [],
  };
}
