import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../styling';
import {Text, View, ViewProps} from 'react-native';
import useIntlFormatter from '../../hooks/useIntlFormatter';

export interface PoolMinerSpeedProps extends ViewProps {
  expired: boolean;
  speed?: number;
}

export default observer(
  ({expired, style, speed, ...rest}: PoolMinerSpeedProps) => {
    const {intFormatter} = useIntlFormatter();
    if (speed === undefined) {
      return null;
    }
    return (
      <SpeedView expired={expired} style={style} {...rest}>
        <Speed>{intFormatter(speed)} H/s.</Speed>
      </SpeedView>
    );
  },
);

const SpeedView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary,
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
  expired: {
    backgroundColor: theme.palette.error,
  },
}));

const Speed = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.056,
    color: theme.select(
      theme.contrast(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
  },
}));
