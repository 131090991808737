import {observer} from 'mobx-react-lite';
import React from 'react';
import {View, Text} from 'react-native';

import {variance} from '../styling';
import {useCalendarInfo} from './CalendarModalSettings';

export default observer(function CalendarModalDays() {
  const calendarBaseInfo = useCalendarInfo();
  return (
    <RootView>
      {calendarBaseInfo.shortDays.map((day, index) => (
        <CalendarDaysCell key={index}>{day}</CalendarDaysCell>
      ))}
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10,
  },
}));

const CalendarDaysCell = variance(Text)((theme) => ({
  root: {
    color: theme.palette.darkText,
    flexBasis: `${100 / 7}%`,
    height: 44,
    textAlign: 'center',
    lineHeight: 44,
    fontWeight: 600,
    fontSize: 13,
  },
}));
