import {ISODateString} from '../Time';

export default abstract class IntlFormatter {
  static fallbackLocale = 'en-US';

  private static baseDateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };

  private static baseNumberOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  };

  static formatNumber = (
    value: string | number,
    currentLocale: string,
    options?: Intl.NumberFormatOptions,
  ) => {
    try {
      const numberValue = typeof value === 'string' ? parseFloat(value) : value;
      if (isNaN(numberValue)) {
        return value;
      }

      const formatter = new Intl.NumberFormat(currentLocale, {
        ...this.baseNumberOptions,
        ...options,
      });

      return formatter.format(numberValue);
    } catch (error) {
      console.error('Error in formatNumber:', error);
      return value;
    }
  };

  static formatDate = (
    date: ISODateString | number,
    currentLocale: string,
    options?: Intl.DateTimeFormatOptions,
  ) => {
    try {
      const parsedDate = new Date(date);

      if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date format!');
      }

      const formatter = new Intl.DateTimeFormat(
        currentLocale || this.fallbackLocale,
        {...this.baseDateOptions, ...options},
      );

      return formatter.format(parsedDate);
    } catch (error) {
      console.error('Error in formatDate:', error);
      return date;
    }
  };
}
