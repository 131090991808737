import React, {useCallback, useEffect, useState} from 'react';
import {
  Platform,
  RefreshControl,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import HistorySvg from '../assets/svg/colorless/history.svg';
import PayoutColoredSvg from '../assets/svg/colored/payout.svg';
import {useRoot, useStrings} from '../Root/hooks';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import WithdrawState from './WithdrawState';
import PanelTitle from './PanelTitle';
import {useIsFocused} from '@react-navigation/native';
import {PageScrollView} from '../containers';
import {useTemplates} from '../Root/hooks';
import {AdSpot, Satoshi} from '../ApiStore';
import {createStylesHook, useTheme} from '../styling';
import {useHeaderHeight} from '../Navigation/hooks';
import {sized} from '../Svg';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import range from '../utils/range';
import Markdown from '../components/Markdown';
import toFixedTrunc from '../utils/toFixedTrunc';
import {expr} from 'mobx-utils';
import {first} from 'lodash';
import AdsBanner from './AdsBanner';
import {KeyboardAvoidingView} from 'react-native-keyboard-controller';
import useIntlFormatter from '../hooks/useIntlFormatter';

export interface WithdrawScreenProps {
  title?: string;
  goToWithdrawalHistory: () => void;
  goToPaymentSuccess: (amount: Satoshi) => void;
}

export default observer((props: WithdrawScreenProps) => {
  const {title, goToPaymentSuccess, goToWithdrawalHistory} = props;
  const headerHeight = useHeaderHeight();
  const theme = useTheme();
  const styles = useStyles();
  const strings = useStrings();
  const templates = useTemplates();
  const root = useRoot();
  const {advertHelper, windowDimensionsState} = useRoot();
  const [state] = useState(() => new WithdrawState(root));
  const isFocused = useIsFocused();
  const {intFormatter} = useIntlFormatter();
  const isDesktop = expr(() => windowDimensionsState.window.width > 1000);
  const advertMobileBanner = expr(() =>
    first(advertHelper.spotByBannerList?.get(AdSpot.WithdrawMobile)),
  );
  const advertDesktopBanner = expr(() =>
    first(advertHelper.spotByBannerList?.get(AdSpot.WithdrawDesktop)),
  );
  const advertBanner = expr(() =>
    isDesktop ? advertDesktopBanner : advertMobileBanner,
  );
  useEffect(() => {
    if (isFocused) {
      // noinspection JSIgnoredPromiseFromCall
      state.fetch();
    }
  }, [isFocused, state]);
  const submit = useCallback(async () => {
    const amount = await state.onSubmit();
    if (amount !== undefined) {
      goToPaymentSuccess(amount);
    }
  }, [goToPaymentSuccess, state]);

  const handlePasteMaxValue = useCallback(() => {
    const handlers = state.amount.handlers;
    const onChangeText = handlers.onChangeText;
    const maxAmount = state.maxAmount;
    if (onChangeText && maxAmount) {
      onChangeText(toFixedTrunc(maxAmount, 8));
    }
  }, [state]);
  const getBanner = () => {
    if (advertBanner) {
      return <AdsBanner isDesktop={isDesktop} advertItem={advertBanner} />;
    }
  };
  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={headerHeight}
      style={styles.root}
      behavior="padding">
      <DesktopWebAppHeader />
      <PageScrollView
        style={styles.scroll}
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps="handled"
        refreshControl={
          <RefreshControl
            refreshing={state.isLoading}
            onRefresh={state.fetch}
          />
        }>
        <View style={styles.panel}>
          <View style={styles.content}>
            {title === undefined && (
              <PanelTitle style={styles.panelTitle}>
                {strings['withdraw.title']}
              </PanelTitle>
            )}
            <Markdown
              styles={{paragraph: styles.description, link: styles.link}}>
              {templates['withdraw.description']({
                email: root.auth.email || 'user@example.com',
              })}
            </Markdown>
            <View
              style={[
                styles.group,
                state.amount.isFocused && styles.groupActive,
              ]}>
              <TextInput
                style={styles.textInput}
                placeholder={strings['withdraw.inputPlaceholder']}
                placeholderTextColor={theme.palette.textSecondary}
                numberOfLines={1}
                keyboardAppearance={theme.select('light', 'dark')}
                keyboardType="numeric"
                {...state.amount.handlers}
              />
              {state.maxAmount !== undefined && (
                <PressableOpacity
                  style={styles.maximumView}
                  onPress={handlePasteMaxValue}>
                  <Text style={styles.maximum}>
                    {strings['common.maxShort']}{' '}
                    {intFormatter(toFixedTrunc(state.maxAmount, 8))} BTC
                  </Text>
                  <Text
                    ellipsizeMode="clip"
                    numberOfLines={1}
                    style={styles.maximumDotted}>
                    {range(50).map((_) => '.')}
                  </Text>
                </PressableOpacity>
              )}
            </View>
            <Text style={[styles.helper, state.errorText && styles.error]}>
              {state.errorText ? strings[state.errorText] : null}
            </Text>
            <TouchableOpacity
              style={styles.historyButtonTouchable}
              onPress={goToWithdrawalHistory}>
              <Button
                Icon={HistoryIcon}
                variant={ButtonVariant.Text}
                color={ButtonColor.Primary}>
                {strings['withdraw.historyButton']}
              </Button>
            </TouchableOpacity>
          </View>

          <View style={styles.footer}>
            <PressableOpacity
              activeOpacity={0.7}
              accessibilityRole="button"
              onPress={submit}>
              <Button Icon={PayoutColoredIcon}>
                {strings['withdraw.sendButton']}
              </Button>
            </PressableOpacity>
          </View>
        </View>
        <View style={styles.banner}>{getBanner()}</View>
      </PageScrollView>
    </KeyboardAvoidingView>
  );
});

const HistoryIcon = sized(HistorySvg, 21);
const PayoutColoredIcon = sized(PayoutColoredSvg, 24, 16);

const useStyles = createStylesHook((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
  scroll: {
    flexGrow: 1,
    flexShrink: 1,
  },
  container: {
    padding: 25,
    justifyContent: 'center',
    ...Platform.select({
      web: {
        ...theme.mediaQuery({
          1200: {
            paddingVertical: 150,
          },
        }),
      },
    }),
  },
  banner: {
    width: '100%',
    ...theme.mediaQuery({
      1281: {
        maxWidth: 650,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
  panel: {
    overflow: 'hidden',
    backgroundColor: theme.select(theme.palette.background, theme.palette.base),
    borderRadius: 15,
    borderWidth: 1,
    borderColor: theme.palette.border,
    marginBottom: 15,
    ...theme.mediaQuery({
      1281: {
        maxWidth: 650,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 25,
      },
    }),
  },
  content: {
    padding: 25,
    paddingBottom: 15,
  },
  footer: {
    padding: 15,
    backgroundColor: theme.select(theme.palette.base, theme.palette.background),
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
  },
  panelTitle: {
    marginBottom: 28,
  },
  description: {
    ...theme.fontByWeight(),
    fontSize: 13,
    lineHeight: 22,
    letterSpacing: 0.0628572,
    color: theme.palette.textPrimary,
  },
  link: {
    color: theme.palette.secondary,
    fontStyle: 'normal',
    textDecorationLine: 'none',
  },
  email: {
    ...theme.fontByWeight('bold'),
  },
  group: {
    marginTop: 30,
    paddingBottom: 11,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.disabled,
    flexDirection: 'row',
  },
  groupActive: {
    borderBottomColor: theme.palette.secondary,
  },
  textInput: {
    height: 19,
    flexGrow: 1,
    flexShrink: 1,
    paddingStart: 0,
    paddingTop: 0,
    paddingEnd: 0,
    paddingBottom: 0,
    ...theme.fontByWeight(),
    fontSize: 14,
    lineHeight: 19,
    letterSpacing: 0.04,
    color: theme.palette.textPrimary,
    minWidth: 0,
    ...Platform.select({web: {outline: 'none'}, default: {}}),
  },
  maximumView: {
    marginTop: 3,
    marginStart: 10,
  },
  maximum: {
    ...theme.fontByWeight('bold'),
    fontSize: 10,
    lineHeight: 14,
    letterSpacing: 0.0285714,
    color: theme.palette.secondary,
  },
  maximumDotted: {
    position: 'absolute',
    bottom: -5,
    left: 0,
    right: 0,
    fontSize: 11,
    color: theme.palette.secondary,
  },
  helper: {
    marginTop: 12,
    ...theme.fontByWeight(),
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.0419048,
    color: theme.palette.textSecondary,
  },
  error: {
    color: theme.palette.error,
  },
  historyButtonTouchable: {
    alignSelf: 'center',
    marginTop: 25,
  },
}));
