import {
  GestureResponderEvent,
  Text,
  TouchableWithoutFeedback,
  ViewProps,
  View,
  TouchableWithoutFeedbackProps,
} from 'react-native';
import {Millisecond} from '../utils/time';
import {observer} from 'mobx-react-lite';

import React from 'react';
import {useRoot} from '../Root/hooks';
import {PanelView} from '../components';
import CaretDownSvg from '../assets/svg/colorless/caretDown.svg';
import {sized} from '../Svg';
import {createStylesHook, useTheme} from '../styling';
import useIntlFormatter from '../hooks/useIntlFormatter';

interface FilterViewProps extends ViewProps {
  onPress?: (event: GestureResponderEvent) => void;
  from: Millisecond;
  to: Millisecond;
  modalShown: boolean;
}

export default observer(
  ({onPress, modalShown, from, to, ...rest}: FilterViewProps) => {
    const theme = useTheme();
    const styles = useStyles();
    const {translation} = useRoot();
    const {strings} = translation;
    const {dateFormatter} = useIntlFormatter();
    const Wrapper = (
      modalShown ? View : TouchableWithoutFeedback
    ) as React.ComponentType<ViewProps | TouchableWithoutFeedbackProps>;
    return (
      <View style={styles.background}>
        <View style={styles.filter} {...rest}>
          <PanelView style={styles.periodField}>
            <View style={styles.periodLabelView}>
              <Text style={styles.periodLabelText}>
                {strings['common.period']}
              </Text>
            </View>
            <Wrapper onPress={onPress}>
              <View style={styles.periodValueView}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="middle"
                  style={styles.periodValueText}>
                  {dateFormatter(from, {month: '2-digit', day: '2-digit'})}
                  {' – '}
                  {dateFormatter(to, {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </Text>
                <View style={styles.periodValueIcon}>
                  <CaretDownIcon color={theme.palette.icon} />
                </View>
              </View>
            </Wrapper>
          </PanelView>
        </View>
      </View>
    );
  },
);

const CaretDownIcon = sized(CaretDownSvg, 8);

const useStyles = createStylesHook((theme) => ({
  background: {
    backgroundColor: theme.palette.background,
  },
  filter: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    backgroundColor: theme.palette.backdrop,
    ...theme.mediaQuery({
      1000: {
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        borderBottomWidth: 0,
      },
    }),
  },
  periodField: {
    marginVertical: 10,
    marginHorizontal: 9,
    flexDirection: 'row',
  },
  periodLabelView: {
    borderEndWidth: 1,
    borderEndColor: theme.palette.border,
    backgroundColor: theme.palette.backdrop,
    paddingStart: 20,
    paddingTop: 9,
    paddingEnd: 19,
    paddingBottom: 7,
  },
  periodLabelText: {
    ...theme.fontByWeight('bold'),
    fontSize: 12,
    lineHeight: 16,
    color: theme.palette.textSecondary,
  },
  periodValueView: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    paddingStart: 15,
    paddingTop: 9,
    paddingBottom: 7,
    cursor: 'pointer',
  },
  periodValueText: {
    flexGrow: 1,
    flexShrink: 1,
    ...theme.fontByWeight('bold'),
    fontSize: 12,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
  periodValueIcon: {
    alignSelf: 'center',
    marginHorizontal: 10,
  },
}));
