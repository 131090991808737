import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../../../Root/hooks';
import {Text} from 'react-native';
import React from 'react';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {expr} from 'mobx-utils';
import getTotalWorkersMined from '../../../getters/getTotalWorkersMined';
import toFixedTrunc from '../../../../utils/toFixedTrunc';
import useIntlFormatter from '../../../../hooks/useIntlFormatter';

export interface GroupTotalMinedProps {
  workers: readonly ReadonlyDeep<WorkerEntity>[];
}

export default observer(({workers}: GroupTotalMinedProps) => {
  const {
    dashboardStore: {getWorkerBalanceById},
  } = useRoot();
  const totalMined = expr(() =>
    getTotalWorkersMined(workers, getWorkerBalanceById),
  );
  const {intFormatter} = useIntlFormatter();

  return <Text>{intFormatter(toFixedTrunc(totalMined, 8))}</Text>;
});
