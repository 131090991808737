import React, {createContext, useContext, useState, ReactNode} from 'react';
import CalendarModal from './CalendarModal';
import {Millisecond} from '../Time';

type CalendarContextType = {
  pick: (initialDate?: Date) => Promise<Date>;
};

const CalendarContext = createContext<CalendarContextType | null>(null);

export const CalendarProvider: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [resolveFn, setResolveFn] = useState<(date: Date) => void>();
  const [initialDate, setInitialDate] = useState<Date>(new Date());

  const pick = (date: Date = new Date()): Promise<Date> => {
    setIsVisible(true);
    setInitialDate(date);
    return new Promise<Date>((resolve) => {
      setResolveFn(() => resolve);
    });
  };

  const handleDatePick = (date: Millisecond) => {
    const formattedDate = new Date(date);
    if (resolveFn) {
      resolveFn(formattedDate);
    }
    setIsVisible(false);
  };

  return (
    <CalendarContext.Provider value={{pick}}>
      {children}
      <CalendarModal
        isVisible={isVisible}
        onDateSelect={handleDatePick}
        close={() => setIsVisible(false)}
        initialDate={initialDate}
      />
    </CalendarContext.Provider>
  );
};

export const useCalendar = (): CalendarContextType => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error('useCalendar must be used within a CalendarProvider');
  }
  return context;
};
