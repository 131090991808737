import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Text} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import Camera from './Camera';
import {BarcodeScanningResult} from 'expo-camera';
import {createStylesHook} from '../styling';
import {LinkWorkerModalsManager} from '../LinkWorkerModalsManager';
import {useStrings} from '../Root/hooks';
import {observable} from 'mobx';
import Markdown from '../components/Markdown';

export type QRCodeScannerScreenProps = {
  goToDashboard: () => void;
};

type ShownLinkWorkerProps = {visible: false} | {visible: true; linkKey: string};

export default observer((props: QRCodeScannerScreenProps) => {
  const strings = useStrings();
  const [linkWorkerModalBox] = useState(() =>
    observable.box<ShownLinkWorkerProps>({
      visible: false,
    }),
  );
  const onBarCodeScanned = useCallback(
    (scanningResult: BarcodeScanningResult) => {
      const url = scanningResult.data;
      const key = url.split('?key=')[1];
      if (key && !linkWorkerModalBox.get().visible) {
        linkWorkerModalBox.set({visible: true, linkKey: key});
      }
    },
    [linkWorkerModalBox],
  );
  const modal = linkWorkerModalBox.get();
  const styles = useStyles();
  return (
    <>
      <SafeAreaView style={styles.content} edges={['bottom']}>
        <Text style={styles.title}>{strings['qrCode.title']}</Text>
        <Markdown
          styles={{
            link: styles.link,
            paragraph: styles.paragraph,
          }}>
          {strings['qrCode.description']}
        </Markdown>
        <Camera style={styles.cameraView} onBarCodeScanned={onBarCodeScanned} />
        <Text style={styles.helperText}>{strings['qrCode.helper']}</Text>
      </SafeAreaView>
      {modal.visible && (
        <LinkWorkerModalsManager {...props} linkKey={modal.linkKey} />
      )}
    </>
  );
});

const useStyles = createStylesHook((theme) => ({
  content: {
    flex: 1,
    padding: 20,
  },
  title: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    marginBottom: 10,
    fontSize: 16,
    lineHeight: 26,
  },
  cameraView: {
    flex: 1,
    marginBottom: 20,
  },
  helperText: {
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.04,
    color: theme.palette.primary,
  },
  paragraph: {
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.04,
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    marginTop: 0,
    marginBottom: 20,
  },
  link: {
    color: theme.palette.secondary,
    fontStyle: 'normal',
    textDecorationLine: 'none',
  },
}));
