import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ProposalTable} from './ProposalTable';
import {useForcedContext} from '../../../../context';
import {PurchaseScreenContext} from '../../../PurchaseScreenContext';
import {IntervalRowProps} from './IntervalRow';
import {useStrings} from '../../../../Root/hooks';
import {
  ManagedProposalSubscription,
  Proposal,
} from '../../../../ProposalsState';
import {
  MinerOffer,
  MinerProductOffer,
  MinerSubscriptionOffer,
  OfferPurchaseType,
  PaymentMethod,
} from '../../../../InAppOffersService';
import {Hashrate, Interval} from '../../../../ApiStore';
import StaticPurchaseScreenHelper from '../../../states/StaticPurchaseScreenHelper';
import useIntlFormatter from '../../../../hooks/useIntlFormatter';

type Item =
  | Proposal<MinerProductOffer>
  | ManagedProposalSubscription<MinerSubscriptionOffer>;

export default observer(function ProposalTableBinding() {
  const {
    state: {
      uniqIntervalList,
      doubleProposalByIntervalByHashrate,
      selectedHashrate,
      selectedPaymentMethod,
      selectedInterval,
      selectHashrate,
      selectInterval,
    },
  } = useForcedContext(PurchaseScreenContext);
  const strings = useStrings();
  const {getPrice, checkIsSelected, getDiscounts, getPricePerMonthPerHashrate} =
    useProposalTableHelper(
      selectedHashrate,
      selectedInterval,
      selectedPaymentMethod,
    );
  const items: IntervalRowProps[] = useMemo(() => {
    if (!selectedHashrate) {
      return [];
    }
    const map = doubleProposalByIntervalByHashrate.get(selectedHashrate);
    if (!map) {
      return [];
    }
    return uniqIntervalList.map((interval, index) => {
      const double = map.get(interval);
      const doubleProposal = double
        ? StaticPurchaseScreenHelper.filterDoublePoolProposal(double)
        : null;
      const proposal = doubleProposal?.product || doubleProposal?.subscription;
      if (!proposal) {
        return {
          selected: false,
          disabled: true,
          isLast: index === uniqIntervalList.length - 1,
          duration: interval,
          priceText: strings['common.notAvailable'],
          suffix: strings['common.shortMonth'],
        };
      }
      const disabled =
        !proposal.available ||
        (proposal.offer.purchaseType === OfferPurchaseType.MinerSubscription &&
          proposal.offer.bought);
      return {
        selected: checkIsSelected(proposal),
        disabled: disabled,
        isLast: index === uniqIntervalList.length - 1,
        priceText: getPrice(proposal),
        pricePerMonthPerHashrate: getPricePerMonthPerHashrate(proposal),
        duration: interval,
        suffix: strings['common.shortMonth'],
        hashrate: proposal.offer.poolMinerConfig.hash_rate,
        discounts: getDiscounts(proposal),
      };
    });
  }, [
    getDiscounts,
    selectedHashrate,
    doubleProposalByIntervalByHashrate,
    uniqIntervalList,
    checkIsSelected,
    getPrice,
    getPricePerMonthPerHashrate,
    strings,
  ]);
  const onSelectProposal = useCallback(
    (proposal: IntervalRowProps) => {
      if (!proposal.disabled && proposal.hashrate) {
        selectHashrate(proposal.hashrate);
        selectInterval(proposal.duration);
      }
    },
    [selectHashrate, selectInterval],
  );
  return <ProposalTable onItemPress={onSelectProposal} items={items} />;
});

export const useProposalTableHelper = (
  selectedHashrate: Hashrate | undefined,
  selectedInterval: Interval | undefined,
  selectedPaymentMethod: PaymentMethod,
) => {
  const strings = useStrings();
  const {intFormatter} = useIntlFormatter();
  const getPrice = useCallback(
    (proposal: Proposal<MinerOffer>) => {
      if (!proposal.available) {
        return strings['common.notAvailable'];
      }
      const payment = proposal.offer.payments.get(selectedPaymentMethod);
      if (!payment) {
        return strings['common.notAvailable'];
      }
      const {currency, uiPricePerMonth} = payment;
      return `${intFormatter(uiPricePerMonth)} ${currency}`;
    },
    [selectedPaymentMethod, strings, intFormatter],
  );
  const getPricePerMonthPerHashrate = useCallback(
    (proposal: Proposal<MinerOffer>) => {
      const payment = proposal.offer.payments.get(selectedPaymentMethod);
      if (!payment) {
        return strings['common.notAvailable'];
      }
      const {uiPricePerMonthPerHashrate, currency} = payment;
      return `${
        uiPricePerMonthPerHashrate && intFormatter(uiPricePerMonthPerHashrate)
      } ${currency} / 1000 H/s`;
    },
    [selectedPaymentMethod, strings, intFormatter],
  );
  const getDiscounts = useCallback(
    (proposal: Proposal<MinerOffer>) => {
      const payment = proposal.offer.payments.get(selectedPaymentMethod);
      return payment?.discounts?.map((d) => d.percent) || [];
    },
    [selectedPaymentMethod],
  );
  const checkIsSelected = useCallback(
    (proposal: Item) => {
      const offerHashrate = proposal.offer.poolMinerConfig.hash_rate;
      const offerInterval = proposal.offer.interval;
      if (
        proposal.offer.purchaseType === OfferPurchaseType.MinerSubscription &&
        proposal.offer.bought
      ) {
        return false;
      }
      return (
        offerHashrate === selectedHashrate && offerInterval === selectedInterval
      );
    },
    [selectedHashrate, selectedInterval],
  );

  return {getPrice, getDiscounts, getPricePerMonthPerHashrate, checkIsSelected};
};
