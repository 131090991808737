import React, {useEffect} from 'react';
import {Platform, StyleSheet, View, ViewProps} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import RNBootSplash from 'react-native-bootsplash';
import {NavigationRoot} from './Navigation';
import RootProvider from './Root/RootProvider';
import Portal from './Portal';
import {AuthSplashBinding} from './AuthSplash';
import {useStyles} from './styling';
import {Headless} from './Headless';
import {FlashMessage as CustomFlashMessage} from './components';
import FlashMessage from 'react-native-flash-message';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import ErrorModalProvider from './ExceptionHandler';
import InAppLoader from './components/InAppLoader';
import {UpdateAppCheckerModal} from './UpdateApp';
import {PushAdvertSplashContainer} from './PushAdvertSplash';
import {KeyboardProvider} from 'react-native-keyboard-controller';
import {SystemBars} from 'react-native-edge-to-edge';
import {useRoot} from './Root/hooks';
import {CalendarProvider} from './Calendar/CalendarContext';

export const Wrapper = ({children}: ViewProps) => {
  const {
    appearance: {isDark},
  } = useRoot();
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      backgroundColor: theme.palette.back,
    },
  }));
  useEffect(() => {
    if (Platform.OS !== 'web') {
      // noinspection JSIgnoredPromiseFromCall
      RNBootSplash.hide();
    }
  }, []);

  return (
    <GestureHandlerRootView style={{flex: 1}}>
      <KeyboardProvider>
        <SystemBars style={isDark ? 'light' : 'dark'} />
        <SafeAreaProvider>
          <View style={styles.root}>{children}</View>
        </SafeAreaProvider>
      </KeyboardProvider>
    </GestureHandlerRootView>
  );
};

export type AppProps = {
  headless: Headless;
};

const App: React.FC<AppProps> = ({headless}) => (
  <RootProvider headless={headless}>
    <Wrapper>
      <Portal.Host>
        <CalendarProvider>
          <NavigationRoot />
          <PushAdvertSplashContainer />
          <AuthSplashBinding />
          <ErrorModalProvider />
          <UpdateAppCheckerModal />
        </CalendarProvider>
      </Portal.Host>

      <View style={styles.layer}>
        <InAppLoader />
      </View>
      <FlashMessage MessageComponent={CustomFlashMessage} />
    </Wrapper>
  </RootProvider>
);

const styles = StyleSheet.create({
  layer: {
    backgroundColor: 'transparent',
    pointerEvents: 'box-none',
    ...StyleSheet.absoluteFillObject,
  },
});

export default App;
