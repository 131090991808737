import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../../styling';
import {Text, View} from 'react-native';
import {sized} from '../../../../Svg';
import BtcSvg from '../../../../assets/svg/colored/btc.svg';
import {useRoot} from '../../../../Root/hooks';
import {PressableOpacity} from '../../../../components';
import useTutorialMeasure from '../../../../InteractiveTutorial/useTutorialMeasure';
import {StackElementKey} from '../../../../InteractiveTutorial';
import useIntlFormatter from '../../../../hooks/useIntlFormatter';

export type BtcBalanceProps = {
  goToWithdraw: () => void;
};

const TUTORIAL_KEY: StackElementKey = 'BALANCE';

export default observer(function BtcBalance({goToWithdraw}: BtcBalanceProps) {
  const {dashboardStore} = useRoot();
  const [ref, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);
  const {intFormatter} = useIntlFormatter();
  return (
    <Root
      onPress={goToWithdraw}
      ref={ref}
      onLayout={onLayout}
      key={getForceKey()}>
      <BtcIconView>
        <BtcIcon />
      </BtcIconView>
      <BalanceView>
        <BtcValue>
          {dashboardStore.balance.btc &&
            intFormatter(dashboardStore.balance.btc)}{' '}
          BTC
        </BtcValue>
        <UsdValue>
          USD{' '}
          {dashboardStore.balance.approxUsd &&
            intFormatter(dashboardStore.balance.approxUsd)}
        </UsdValue>
      </BalanceView>
    </Root>
  );
});

const BtcIcon = sized(BtcSvg, 40);

const Root = variance(PressableOpacity)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderRightWidth: 1,
    borderRightColor: theme.palette.border,
  },
}));

const BtcIconView = variance(View)(() => ({
  root: {
    marginRight: 10,
  },
}));

const BalanceView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const BtcValue = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    letterSpacing: 0.051,
    fontSize: 24,
    lineHeight: 28,
    color: theme.palette.textPrimary,
    marginRight: 10,
  },
}));

const UsdValue = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 13,
    lineHeight: 15,
    letterSpacing: 0.034,
    color: theme.palette.textSecondary,
  },
}));
