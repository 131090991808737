import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {ScrollView, Text, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useRoot, useStrings} from '../Root/hooks';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import useShareSetupLink from './useShareSetupLink';
import useDownloadMinerLink from './useDownloadMinerLink';
import ClipboardWorkerIdSuccess from './ClipboardWorkerIdSuccessModal';
import {createStylesHook, variance} from '../styling';
import {Container} from '../components/Grid';
import {colored, sized} from '../Svg';
import useGetNewWorkerId from './useGetNewWorkerId';
import PinkLinkSvg from '../assets/svg/colorless/pinLink.svg';
import QrCodeTargetSvg from '../assets/svg/colorless/qrCodeTarget.svg';
import CircledFlashSvg from '../assets/svg/colorless/circledFlash.svg';
import {expr} from 'mobx-utils';
import {ApiMode} from '../farmApi';
import {DesktopWebAppHeader} from '../DesktopWebAppHeader';
import PlusSvg from '../assets/svg/colorless/plus.svg';
import {showMessage} from 'react-native-flash-message';
import CheckSvg from '../assets/svg/colorless/check.svg';
import {observable} from 'mobx';
import Markdown from '../components/Markdown';
import BtcSvg from '../assets/svg/colored/btc.svg';
import {DetectPlatform} from '../DetectPlatform';

const INITIAL_GET_WORKER_ID_MODAL = {
  visible: false,
  text: '',
  id: '',
};

export type AddWorkerScreenProps = {
  goToAddPoolMiner(): void;
  goToQRCodeScanner(): void;
  goToDashboard(): void;
  goToProducts(): void;
};

export default observer(
  ({
    goToAddPoolMiner,
    goToQRCodeScanner,
    goToDashboard,
    goToProducts,
  }: AddWorkerScreenProps) => {
    const [getWorkerIdModalBox] = useState(() =>
      observable.box(INITIAL_GET_WORKER_ID_MODAL),
    );
    const {apiStore, connectedClient, share, configPickerModal, clipboard} =
      useRoot();
    const strings = useStrings();
    const {
      currentSubscriptionState: {subscription},
    } = useRoot();
    const {shareLink} = useShareSetupLink();
    const {copyLink} = useDownloadMinerLink();
    const {getNewWorkerId} = useGetNewWorkerId();
    const openPickDemoConfiguration = useCallback(async () => {
      const configuration = await configPickerModal.ask();
      if (configuration) {
        await connectedClient.call('new_demo_worker', {configuration});
      }
      goToDashboard();
    }, [connectedClient, configPickerModal, goToDashboard]);
    const handleGetWorkerId = useCallback(async () => {
      const result = await getNewWorkerId();
      if (result?.workerConnectionId) {
        clipboard.setString(result.workerConnectionId);
        showMessage({
          message: strings['action.theValueHasBeenCopiedToClipboard'],
          duration: 3000,
          type: 'success',
          icon: 'success',
          renderFlashMessageIcon: () => (
            <FlashIcon>
              <CheckIcon />
            </FlashIcon>
          ),
        });
        getWorkerIdModalBox.set({
          visible: true,
          id: result.workerConnectionId,
          text: strings['worker.newWorkerId'],
        });
      }
    }, [clipboard, getNewWorkerId, getWorkerIdModalBox, strings]);
    const styles = useStyles();
    const isRealMode = apiStore.mode === ApiMode.Real;
    const freeRealSlots =
      expr(() =>
        isRealMode && subscription
          ? subscription.maxSlots - subscription.slots
          : null,
      ) ?? 0;
    const realDesc =
      freeRealSlots > 0
        ? strings['addWorker.computer.desc.slots']
        : strings['addWorker.computer.desc'];

    const addPoolMiner = (
      <View>
        <Text style={styles.title}>{strings['addWorker.poolMiner.title']}</Text>
        <Text style={styles.description}>
          {strings['addWorker.poolMiner.desc']}
        </Text>
        <PressableOpacity onPress={goToAddPoolMiner}>
          <Button Icon={CircledFlashIcon} variant={ButtonVariant.Highlighted}>
            {strings['action.worker.addPoolMiner']}
          </Button>
        </PressableOpacity>
      </View>
    );

    const getMoreBtc = (
      <View>
        <Text style={styles.title}>
          {strings['addWorker.getMoreBtc.title']}
        </Text>
        <Text style={styles.description}>
          {strings['addWorker.getMoreBtc.description']}
        </Text>
        <PressableOpacity onPress={goToProducts}>
          <Button Icon={BtcIcon} variant={ButtonVariant.Highlighted}>
            {strings['addWorker.getMoreBtc.get']}
          </Button>
        </PressableOpacity>
      </View>
    );

    const addComputer = (
      <View>
        <Text style={styles.title}>{strings['addWorker.computer.title']}</Text>
        <Text style={styles.description}>{realDesc}</Text>
        <PressableOpacity style={styles.offset} onPress={handleGetWorkerId}>
          <Button color={ButtonColor.Primary} variant={ButtonVariant.Contained}>
            {strings['action.worker.copyNewWorkerId']}
          </Button>
        </PressableOpacity>
        {share.isSupported && (
          <PressableOpacity style={styles.offset} onPress={shareLink}>
            <Button
              color={ButtonColor.Primary}
              variant={ButtonVariant.Contained}>
              {strings['action.worker.shareSetupLink']}
            </Button>
          </PressableOpacity>
        )}
        <PressableOpacity onPress={copyLink}>
          <Button Icon={PinLinkIcon} variant={ButtonVariant.Highlighted}>
            {strings['action.worker.getSetupLink']}
          </Button>
        </PressableOpacity>
      </View>
    );

    const addDemoMiner = (
      <View>
        <Text style={styles.title}>{strings['action.addDemoMiner']}</Text>
        <Text style={styles.description}>
          {strings['modal.setUpWorkerDemo.description']}
        </Text>
        <View>
          <PressableOpacity
            style={styles.offset}
            onPress={openPickDemoConfiguration}>
            <Button Icon={PlusIcon} variant={ButtonVariant.Highlighted}>
              {strings['dashboard.panel.demoButton']}
            </Button>
          </PressableOpacity>
        </View>
      </View>
    );

    const scanQr = (
      <View>
        <Text style={styles.title}>
          {strings['addWorker.scanQR.demo.title']}
        </Text>
        <Markdown
          styles={{
            paragraph: styles.description,
            link: styles.link,
          }}>
          {strings['addWorker.scanQR.demo.desc']}
        </Markdown>
        <PressableOpacity onPress={goToQRCodeScanner}>
          <Button Icon={QrCodeTargetIcon} variant={ButtonVariant.Highlighted}>
            {strings['action.worker.scanQrCode']}
          </Button>
        </PressableOpacity>
      </View>
    );

    const separator = (
      <View style={styles.separator}>
        <View style={styles.separatorLine} />
        <View style={styles.separatorBadge}>
          <Text style={styles.separatorBadgeText}>{strings['common.or']}</Text>
        </View>
      </View>
    );

    const sections = [
      addPoolMiner,
      getMoreBtc,
      !isRealMode && addDemoMiner,
      addComputer,
      (DetectPlatform.isAppleSystem || DetectPlatform.isAndroidSystem) &&
        scanQr,
    ];

    return (
      <>
        <DesktopWebAppHeader />
        <Container style={styles.root}>
          <ScrollView contentContainerStyle={styles.container}>
            <SafeAreaView style={styles.content} edges={['bottom']}>
              {sections.map((section, index) => {
                const isFirst = index === 0;
                if (!section) {
                  return null;
                }
                return (
                  <React.Fragment key={index}>
                    {!isFirst && separator}
                    {section}
                  </React.Fragment>
                );
              })}
            </SafeAreaView>
          </ScrollView>
        </Container>
        {getWorkerIdModalBox.get().visible && (
          <ClipboardWorkerIdSuccess
            title={strings['modal.getWorkerId.title']}
            onDismiss={() =>
              getWorkerIdModalBox.set(INITIAL_GET_WORKER_ID_MODAL)
            }
            description={getWorkerIdModalBox.get().text}
            id={getWorkerIdModalBox.get().id}
          />
        )}
      </>
    );
  },
);

const PlusIcon = sized(PlusSvg, 14);
const BtcIcon = sized(BtcSvg, 20);
const CircledFlashIcon = sized(CircledFlashSvg, 18);
const CheckIcon = colored(sized(CheckSvg, 20), '#fff');
const PinLinkIcon = sized(PinkLinkSvg, 16);
const QrCodeTargetIcon = sized(QrCodeTargetSvg, 20);

const FlashIcon = variance(View)(() => ({
  root: {
    padding: 5,
  },
}));

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
  },
  content: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  title: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    marginBottom: 7,
    fontSize: 20,
    lineHeight: 25,
    letterSpacing: 0.067,
  },
  description: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.04,
    color: theme.palette.textSecondary,
    marginTop: 0,
    marginBottom: 15,
  },
  link: {
    color: theme.palette.secondary,
    textDecorationLine: 'none',
  },
  offset: {
    marginBottom: 7,
  },
  separator: {
    marginHorizontal: -20,
    marginVertical: 15,
  },
  separatorLine: {
    height: 1,
    backgroundColor: theme.palette.border,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 9,
  },
  separatorBadge: {
    marginLeft: 'auto',
    marginRight: 'auto',
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.background,
    borderColor: theme.palette.border,
    height: 20,
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  separatorBadgeText: {
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 0.13,
    textTransform: 'uppercase',
    color: theme.palette.textPrimary,
  },
}));
