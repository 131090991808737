import {batchDisposers, RouterImpl, Service} from '../structure';
import {
  AppAnalytics,
  AppAnalyticsRouterMap,
  AppEvent,
  FIRST_DEMO_MINER_ADDED,
  FIRST_EVENT_MADE,
  FIRST_GET_MINER_ID,
  FIRST_MINER_ADDED,
  FIRST_PURCHASE_MADE,
} from './AppAnalytics';
import {ApiStore} from '../ApiStore';
import {isRequest} from '../JsonRpc';
import StaticAppAnalyticsHelper from './StaticAppAnalyticsHelper';
import {InAppPurchaseManager, PURCHASE_RESULT} from '../InAppPurchaseManager';
import {bind} from '../fp';

export default class AppAnalyticsService implements AppAnalytics, Service {
  private readonly _updates = new RouterImpl<AppAnalyticsRouterMap>();

  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly inAppPurchaseManager: InAppPurchaseManager;
    },
  ) {}

  get updates() {
    return this._updates;
  }

  private _listenLinkMiner() {
    return this._root.apiStore.outgoing.listen((data) => {
      if (isRequest(data) && data.method === 'link_worker') {
        return StaticAppAnalyticsHelper.callIfNotMarked(
          'firstMinerAdded',
          () => {
            this.updates.send(FIRST_MINER_ADDED, FIRST_MINER_ADDED);
          },
        );
      }
    });
  }

  private _listenGetNewMinerId() {
    return this._root.apiStore.outgoing.listen((data) => {
      if (isRequest(data) && data.method === 'new_worker') {
        return StaticAppAnalyticsHelper.callIfNotMarked(
          'firstGetMinerId',
          () => {
            this.updates.send(FIRST_GET_MINER_ID, FIRST_GET_MINER_ID);
          },
        );
      }
    });
  }

  private _listenAddFirstDemoMiner() {
    return this._root.apiStore.demoOutgoing.listen((data) => {
      if (isRequest(data) && data.method === 'new_demo_worker') {
        return StaticAppAnalyticsHelper.callIfNotMarked(
          'firstDemoMinerAdded',
          () => {
            this.updates.send(FIRST_DEMO_MINER_ADDED, FIRST_DEMO_MINER_ADDED);
          },
        );
      }
    });
  }

  private _listenFirstPurchase() {
    return this._root.inAppPurchaseManager.events.listen(
      PURCHASE_RESULT,
      (response) => {
        if (response.success) {
          StaticAppAnalyticsHelper.callIfNotMarked('firstPurchaseMade', () => {
            this.updates.send(FIRST_PURCHASE_MADE, FIRST_PURCHASE_MADE);
          });
        }
      },
    );
  }

  private _onFirstEvent = bind((event: AppEvent) => {
    StaticAppAnalyticsHelper.callIfNotMarked('firstEventMade', () => {
      this.updates.send(FIRST_EVENT_MADE, event);
    });
  }, this);

  private _listenFirstEvent() {
    return batchDisposers(
      this.updates.listen(FIRST_DEMO_MINER_ADDED, this._onFirstEvent),
      this.updates.listen(FIRST_MINER_ADDED, this._onFirstEvent),
      this.updates.listen(FIRST_GET_MINER_ID, this._onFirstEvent),
      this.updates.listen(FIRST_PURCHASE_MADE, this._onFirstEvent),
    );
  }

  subscribe() {
    return batchDisposers(
      this._listenAddFirstDemoMiner(),
      this._listenGetNewMinerId(),
      this._listenLinkMiner(),
      this._listenFirstPurchase(),
      this._listenFirstEvent(),
    );
  }
}
