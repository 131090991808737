import {ReadonlyDeep} from 'type-fest';
import {WorkerGroupEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {observer} from 'mobx-react-lite';
import {useNavigation} from '@react-navigation/native';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {ActionItem, ActionKey, SetupButton} from '../../../common/SetupButton';
import {expr} from 'mobx-utils';
import React from 'react';
import {RemoveWorkerGroupModal, RenameWorkerGroupModal} from '../../../modals';
import {useBoolean} from '../../../../hooks';
import {SlotStatus} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {DashboardScreenProps} from '../../../DashboardScreenProps';

export interface GroupHeaderSetupButtonProps {
  group: ReadonlyDeep<WorkerGroupEntity>;
  isDefaultGroup: boolean;
}

export default observer(
  ({group, isDefaultGroup}: GroupHeaderSetupButtonProps) => {
    const navigation = useNavigation<DashboardScreenProps['navigation']>();

    const [renameModalShown, showRenameModal, hideRenameModal] = useBoolean();
    const [removeModalShown, showRemoveModal, hideRemoveModal] = useBoolean();

    const {currentSubscriptionState} = useRoot();
    const {isPlatformSupported} = currentSubscriptionState;
    const strings = useStrings();
    const actions: ActionItem[] = expr(() => [
      {
        key: ActionKey.Schedule,
        title: strings['workerGroup.action.schedule'],
        onPress: () => {
          navigation.navigate('Schedule', {
            ownerName: group.name,
            workerIds: group.workers.map((_) => _.id),
          });
        },
        disabled:
          group.workers.length === 0 ||
          group.workers.some((_) => _.slot_status === SlotStatus.Inactive) ||
          !isPlatformSupported,
      },
      {
        key: ActionKey.Rename,
        title: strings['action.rename'],
        onPress: showRenameModal,
        disabled: !isPlatformSupported,
      },
      {
        key: ActionKey.AddMoreSlots,
        title: strings['workerGroup.action.addMoreSlots'],
        onPress: () => {
          navigation.navigate('Plan');
        },
        disabled: !isPlatformSupported,
      },
      ...(!isDefaultGroup
        ? [
            {
              key: ActionKey.Remove,
              title: strings['action.delete'],
              onPress: showRemoveModal,
              disabled: group.workers.length > 0 || !isPlatformSupported,
            },
          ]
        : []),
    ]);

    return (
      <>
        <SetupButton actions={actions} />
        {renameModalShown && (
          <RenameWorkerGroupModal onDismiss={hideRenameModal} group={group} />
        )}
        {removeModalShown && (
          <RemoveWorkerGroupModal onDismiss={hideRemoveModal} group={group} />
        )}
      </>
    );
  },
);
