import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {TotalPrice} from '../PurchaseScreen/PoolMinersTab/components/TotalPrice';
import {processProductOffer} from '../PurchaseScreen/PoolMinersTab/processProductOffer';
import {MinerProductOffer, PaymentMethod} from '../InAppOffersService';
import {Proposal} from '../ProposalsState';
import {useRoot} from '../Root/hooks';

type TotalPriceBindingProps = {
  selectedProposal: Proposal<MinerProductOffer> | undefined;
  onPurchasePress: () => Promise<void>;
  openEnterPromoModal: () => void;
  safelyGoToAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
};

export default observer(function TotalPriceBinding(
  props: TotalPriceBindingProps,
) {
  const {
    selectedProposal,
    onPurchasePress,
    getAffiliateIsPending,
    safelyGoToAffiliate,
    openEnterPromoModal,
  } = props;
  const {
    inAppOffers: {error: purchaseStoreError},
    translation,
  } = useRoot();
  const handleBuyPress = useCallback(() => {
    if (selectedProposal?.offer) {
      onPurchasePress();
    }
  }, [onPurchasePress, selectedProposal?.offer]);
  if (purchaseStoreError) {
    return null;
  }
  if (!selectedProposal?.available) {
    return null;
  }
  const computedOffer = processProductOffer(
    selectedProposal.offer,
    PaymentMethod.InApp,
    translation.locale,
  );
  if (!computedOffer) {
    return null;
  }
  return (
    <TotalPrice
      hideBtns
      productOffer={computedOffer}
      subscriptionOffer={undefined}
      onMainOfferPurchasePress={handleBuyPress}
      openEnterPromoModal={openEnterPromoModal}
      safelyGoToAffiliate={safelyGoToAffiliate}
      getAffiliateIsPending={getAffiliateIsPending}
    />
  );
});
