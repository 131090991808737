import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View, Text} from 'react-native';
import CalendarModalDays from './CalendarModalDays';
import {useCalendarInfo} from './CalendarModalSettings';
import CalendarModalMonthSwitcher from './CalendarModalMonthSwitcher';
import CalendarModalMonthView from './CalendarModalMonthView';
import {useCalendarState} from './CalendarModalReducer';
import {
  NEXT_MONTH,
  PREV_MONTH,
  SET_NEW_DAY,
} from './CalendarModalActionsConstant';
import {variance} from '../styling';
import {Millisecond} from '../Time';

import {useStrings} from '../Root/hooks';
import {LG_BREAKPOINT} from '../units/constants';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Button, ButtonVariant, PressableOpacity} from '../components';

export type CalendarModalViewProps = {
  onDateSelect: (from: Millisecond) => void;
  close: () => void;
  initialDate: Date;
};

export default observer(function CalendarModalView({
  onDateSelect,
  close,
  initialDate,
}: CalendarModalViewProps) {
  const [state, dispatch] = useCalendarState(initialDate);
  const calendarBaseInfo = useCalendarInfo();
  const strings = useStrings();
  const onNextMonth = () => dispatch({type: NEXT_MONTH});
  const onPrevMonth = () => dispatch({type: PREV_MONTH});
  const setNewDay = (day: number, month: number) =>
    dispatch({type: SET_NEW_DAY, payload: {day: day, month: month}});

  const handlePress = useCallback(() => {
    onDateSelect(
      new Date(
        state.year,
        state.month,
        state.date.getDate(),
      ).getTime() as Millisecond,
    );
    close();
  }, [onDateSelect, close, state]);
  const paddingBottom = useSafeAreaInsets().bottom;
  return (
    <Root style={{paddingBottom}}>
      <Title>{strings['action.pickADate']}</Title>
      <Calendar>
        <CalendarModalMonthSwitcher
          date={`${calendarBaseInfo.month[state.month]} ${state.year}`}
          onNextMonth={onNextMonth}
          onPrevMonth={onPrevMonth}
        />
        <CalendarModalDays />
        <CalendarModalMonthView
          setNewDay={setNewDay}
          state={state}
          onBack={handlePress}
        />
      </Calendar>
      <PressableOpacity onPress={handlePress}>
        <SubmitButton variant={ButtonVariant.Highlighted}>
          {strings['action.confirm']}
        </SubmitButton>
      </PressableOpacity>
    </Root>
  );
});

const Calendar = variance(View)((theme) => ({
  root: {
    borderRadius: 8,
    width: '100%',
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingBottom: 10,
    paddingTop: 15,
    borderColor: theme.palette.border,
  },
}));

const Root = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 372,
        paddingHorizontal: 5,
      },
    }),
  },
}));

const Title = variance(Text)((theme) => ({
  root: {
    marginBottom: 12,
    alignSelf: 'flex-start',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.darkText,
  },
}));

const SubmitButton = variance(Button)(() => ({
  root: {
    marginTop: 28,
  },
}));
