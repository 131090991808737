import {useCallback} from 'react';
import {useRoot} from '../Root/hooks';
import {IntlFormatter} from '../IntlFormatter';
import {ISODateString} from '../Time';

const useIntlFormatter = () => {
  const {translation} = useRoot();
  const currentLocale = translation.locale || 'en-US';

  const intFormatter = useCallback(
    (value: string | number, options?: Intl.NumberFormatOptions) => {
      return IntlFormatter.formatNumber(value, currentLocale, options);
    },
    [currentLocale],
  );

  const dateFormatter = useCallback(
    (date: ISODateString | number, options?: Intl.DateTimeFormatOptions) => {
      return IntlFormatter.formatDate(date, currentLocale, options);
    },
    [currentLocale],
  );

  return {intFormatter, dateFormatter};
};

export default useIntlFormatter;
