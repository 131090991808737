import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import {Text} from 'react-native';
import React from 'react';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import getTotalWorkersSpeed from '../../../getters/getTotalWorkersSpeed';
import {expr} from 'mobx-utils';
import {useRoot} from '../../../../Root/hooks';
import useIntlFormatter from '../../../../hooks/useIntlFormatter';

export interface GroupTotalSpeedProps {
  workers: readonly ReadonlyDeep<WorkerEntity>[];
}

export default observer(({workers}: GroupTotalSpeedProps) => {
  const {workerStateRegistry} = useRoot();
  const totalSpeed = expr(() =>
    getTotalWorkersSpeed(workers, workerStateRegistry),
  );
  const {intFormatter} = useIntlFormatter();
  return <Text>{intFormatter(totalSpeed.toFixed(0))}</Text>;
});
